"use client";

import initTracker from "@cappelendamm/tracking";
import va from "@vercel/analytics";
import { getCookie } from "@cappelendamm/cookie";
import { flattenRecord } from "@/utils/object";
import { EXPERIMENT_GROUP_COOKIE_NAME } from "@/experiment";
import Logger from "@cappelendamm/logger";

/* window.dataLayer typing can go as far as this example: https://farrant.me/posts/typing-functions-which-might-not-exist */
declare global {
  interface Window {
    dataLayer?: Object[];
    [key: string]: any;
  }
}

const tracker = initTracker({
  debug: process.env.NEXT_PUBLIC_ENVIRONMENT !== "production",
  trackEvent: async (payload, { logger }) => {
    // GA
    const dataLayer = window.dataLayer ?? [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
    // Vercel
    const { event, ...data } = payload;
    va.track(event, flattenRecord(data));
    logger.log("Pushed event with payload: ", payload, "\nStatus: ", dataLayer);
  },
  attachMeta: () => {
    try {
      const experimentGroup = getCookie(EXPERIMENT_GROUP_COOKIE_NAME);
      return { experiment_group: experimentGroup };
    } catch (e) {
      Logger.error(e);
      return {};
    }
  },
});

export default tracker;
